import HandymanIcon from "@mui/icons-material/Handyman";
import HomeIcon from "@mui/icons-material/Home";
import { IMenuItem } from "types/common";

const sidebarConfig: any = [
  {
    title: "Home",
    icon: <HomeIcon />,
    path: "/",
  },
  {
    title: "Master Client",
    icon: <HandymanIcon />,
    path: "/master-client",
  },
  {
    title: "System Users",
    icon: <HandymanIcon />,
    path: "/system-users",
  },
];
export function isItemSelected(item: IMenuItem, urlPath: string) {
  let isSelected = item.Path && urlPath === item.Path;
  return isSelected;
}

export function isChildrenSelected(item: IMenuItem, urlPath: string) {
  return item.Children?.some((i) => isItemSelected(i, urlPath));
}
export default sidebarConfig;
