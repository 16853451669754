import React from "react";
import { maximizePanel, TaskPanelStore } from "stores/taskPanelStore";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function TaskPanel() {
  const { objModals } = TaskPanelStore.useState();
  return (
    <div
      style={{
        position: "fixed",
        right: 0,
        bottom: 0,
        width: "100%",
        boxShadow: "-1px -1px 10px 2px grey",
        background: "white",
        padding: 5,
        display: Object.keys(objModals).length > 0 ? "flex" : "none",
        flexDirection: "row-reverse",
      }}
    >
      {Object.keys(objModals).map((key) => {
        let obj = objModals[key];
        return (
          <div
            style={{
              width: 130,
              height: 35,

              background: "#e1e1e1",
              padding: "5px",
              borderRadius: 10,
              cursor: "pointer",
              userSelect: "none",
              display: "flex",
              alignItems: "center",
            }}
            key={key}
            onClick={() => {
              maximizePanel(obj.id);
            }}
          >
            <ArrowDropUpIcon
              color="primary"
              fontSize="large"
              style={{ padding: 0 }}
            />
            <div
              style={{
                width: "65%",
                textOverflow: "clip",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {obj.title}
            </div>
          </div>
        );
      })}
    </div>
  );
}
