import MenuIcon from "@mui/icons-material/Menu";
import { Icon, IconButton, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import useIsMobile from "hooks/userIsMobile";
import {
  CommonStore,
  setDrawerWidth,
  setIsDrawerOpenOnMobile,
  setMenuFixed,
} from "stores/commonStore";
import SideNavList from "./SideNavList";

let drawerTimeout: any = null;

export default function MainDrawer() {
  const { drawerWidth, isFixedMenu, isDrawerOpenOnMobile } =
    CommonStore.useState((store) => store);
  const isMobile = useIsMobile();
  return (
    <Drawer
      open={isMobile ? isDrawerOpenOnMobile : true}
      variant={isMobile ? "temporary" : "permanent"}
      elevation={10}
      onClose={() => {
        if (!isMobile) return;
        setIsDrawerOpenOnMobile(false);
      }}
      PaperProps={{
        sx: {
          transition: "all 0.2s",
          boxShadow:
            "rgb(0 0 0 / 6%) 0px 5px 5px -3px, rgb(0 0 0 / 4%) 0px 8px 10px 1px, rgb(0 0 0 / 4%) 0px 3px 14px 2px",
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
      onMouseEnter={() => {
        if (isMobile) return;
        drawerTimeout = setTimeout(() => {
          setDrawerWidth("full");
        }, 200);
      }}
      onMouseLeave={() => {
        if (isMobile) return;
        clearTimeout(drawerTimeout);
        setDrawerWidth("mini");
      }}
    >
      <div style={{ display: "flex", padding: "5px 8px" }}>
        <img src="/images/logo.png" style={{ maxHeight: 40 }} />
        <div style={{ flexGrow: 1, minWidth: 50 }}></div>
        {!isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setMenuFixed();
            }}
            edge="start"
            sx={{}}
          >
            {!isFixedMenu ? (
              <Icon>arrow_circle_right</Icon>
            ) : (
              <Icon>arrow_circle_left</Icon>
            )}
          </IconButton>
        )}
      </div>
      <Box sx={{ overflowY: "auto", overflowX: "hidden" }}>
        <SideNavList />
      </Box>
    </Drawer>
  );
}
