import { Store } from "pullstate";
import { createUUID } from "utils/uuidUtils";

interface IPanel {
  id: string;
  title: string;
  setOpen: (o: boolean) => void;
}

interface ITaskPanelState {
  objModals: { [key: string]: IPanel };
}

export const TaskPanelStore = new Store<ITaskPanelState>({
  objModals: {},
});

export function minimizePanel(obj: {
  panelID?: string;
  title?: string;
  setOpen: any;
}) {
  let id = obj.panelID || createUUID();
  TaskPanelStore.update((s) => {
    s.objModals[id] = {
      id,
      title: obj.title || "No title",
      setOpen: obj.setOpen,
    };
  });
  obj.setOpen(false);
  return obj;
}

export function maximizePanel(panelID: string) {
  let objPanel = TaskPanelStore.getRawState().objModals[panelID];
  objPanel.setOpen(true);
  TaskPanelStore.update((s) => {
    delete s.objModals[panelID];
  });
}

export function removePanel(panelID: string) {
  TaskPanelStore.update((s) => {
    delete s.objModals[panelID];
  });
}

// TaskPanelStore.listenToPatches((patches) => {
//   let patch = patches.find((item) =>
//     item.path.includes("selectedMasterClient")
//   );
//   if (patch) {
//     LocalStorageUtils.lsSet("selectedMasterClient", patch.value);
//   }
// });
