import {
  Badge,
  Box,
  Button,
  IconButton,
  lighten,
  List as div,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import RIcon from "common/misc/RIcon";
import SeeMore from "common/misc/SeeMore";
import { useAuth } from "contexts/AuthContext";
import { useList } from "hooks/commonQuries";
import useServerEvent from "hooks/useServerEvent";
import useIsMobile from "hooks/userIsMobile";
import colorList from "layouts/common/colors";
import { useRouter } from "next/router";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import AuthServices from "services/AuthServices";
import { bSdk } from "services/BackendSDKService";
import { queryClient } from "services/ReactQueryClient";
import Utils from "services/Utils";
import { INotificationInstance } from "types/notification-instance";
import SnackBarUtils from "utils/snackBarUtils";
import TrimmedText from "common/misc/TrimmedText";

export default function NotificationMenu() {
  const { user } = useAuth();
  const router = useRouter();
  const isMobile = useIsMobile();
  const lstNotification = useList<INotificationInstance>(
    "notification-instance",
    {
      query: { sort: "CreatedAt", sortOrder: "desc" },
    }
  );
  const unreadNotificationCount = useQuery(
    "unreadNotificationCount",
    async () => {
      let result = await bSdk.cmsQuery("system-users", undefined, {
        route: "/getUnreadNotificationCount",
        method: "get",
        authToken: AuthServices.getToken(),
      });
      return result.data;
    }
  );

  const markNotificationRead = useMutation(async (data: any) => {
    return await bSdk.cmsQuery("notification-instance", data, {
      route: "/markNotificationRead",
      method: "post",
      authToken: AuthServices.getToken(),
    });
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useServerEvent([`notification-update-${user?._id}`], (message) => {
    if (message?.data && message.data._id) {
      queryClient.refetchQueries(["notification-instance"]);
      queryClient.refetchQueries(["unreadNotificationCount"]);
      if (!document.hidden) {
        SnackBarUtils.Show(
          <>
            <h3 dangerouslySetInnerHTML={{ __html: message.data.Title }}></h3>
            <div
              dangerouslySetInnerHTML={{ __html: message.data.Description }}
            ></div>
          </>,
          { persist: true, variant: "info" }
        );
      }
    }
  });

  return (
    <div style={{ width: 60 }}>
      <IconButton size="large" edge="end" onClick={handleClick} color="inherit">
        <Badge badgeContent={unreadNotificationCount.data}>
          <RIcon name="notifications" />
        </Badge>
      </IconButton>
      <Popover
        id={"simple-popover"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              width: isMobile ? "76vw" : "50vw",
              pr: 0.2,
              border: "3px solid gray",
              outline: "3px solid white",
              background: "#f3f7f5",
            },
          },
        }}
      >
        <div style={{ width: "100%" }}>
          {lstNotification.data &&
            lstNotification.data.map((item) => (
              <div
                key={item._id}
                style={{
                  cursor: "pointer",
                  width: "100%",
                  paddingLeft: 8,
                  paddingBottom: 4,
                  paddingTop: 4,
                  borderBottom: "1px solid grey",
                  background: item.IsRead
                    ? undefined
                    : lighten(colorList.Green.light, 0.6),
                }}
                onClick={async () => {
                  if (!item.IsRead) {
                    await markNotificationRead.mutateAsync({
                      NotificationInstanceIDs: [item._id],
                    });
                    queryClient.refetchQueries(["notification-instance"]);
                    unreadNotificationCount.refetch();
                  }
                }}
              >
                <Tooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.Title,
                      }}
                    />
                  }
                  placement="left"
                >
                  <div className="flex-v-center">
                    <div
                      style={{
                        width: "100%",
                        overflowY: "hidden",
                        height: 18,
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.Title,
                        }}
                      />
                    </div>
                    {!item.IsRead && (
                      <Typography
                        style={{ color: "green", marginLeft: "8px" }}
                        fontSize={15}
                        fontWeight={600}
                        component={"span"}
                      >
                        New
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        pr: 2,
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography color={"primary"} paddingLeft={2}>
                        {Utils.convertToSystemDateTimeFormate(item.CreatedAt)}
                      </Typography>
                      {/* <Typography color="purple" paddingLeft={1}>
                        {Utils.dateToAgo(item.CreatedAt)} ago
                      </Typography> */}
                    </Box>
                  </div>
                </Tooltip>

                {item.Description && (
                  <Tooltip
                    title={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.Description,
                        }}
                      />
                    }
                    placement="left"
                  >
                    <div
                      className="flex-v-center"
                      style={{
                        width: "100%",
                        overflowY: "hidden",
                        height: 17,
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.Description,
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              position: "sticky",
              bottom: 0,
              bgcolor: "white",
              borderTop: `2px solid ${colorList.Blue.light}`,
            }}
          >
            <Button
              variant="text"
              sx={{ pr: 1 }}
              onClick={() => {
                setAnchorEl(null);
                router.push("/notification-instance");
              }}
            >
              View All
            </Button>
          </Box>
        </div>
      </Popover>
    </div>
  );
}
