import { IconButton, Tooltip } from "@mui/material";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { LoggedInUserAvatar } from "common/custom/SystemUserAvatar";
import RIcon from "common/misc/RIcon";
import { useAuth } from "contexts/AuthContext";
import useIsMobile from "hooks/userIsMobile";
import { useRouter } from "next/router";
import * as React from "react";
import AuthServices from "services/AuthServices";
import Utils from "services/Utils";

export default function ProfileMenu() {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isMobile = useIsMobile();
  const router = useRouter();

  return (
    <div style={{}}>
      <LoggedInUserAvatar withName={!isMobile} handleClick={handleClick} />
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {auth.user?.ClientPublicIP && (
          <div
            style={{
              margin: "0px 10px",
              // fontSize: "12px",
              borderBottom: "1px solid grey",
            }}
          >
            IP: <b>{auth.user?.ClientPublicIP}</b>{" "}
            <Tooltip title="Copy IP address">
              <IconButton
                onClick={() => {
                  Utils.copyToClipboard(auth.user?.ClientPublicIP);
                }}
              >
                <RIcon name="content_copy" />
              </IconButton>
            </Tooltip>
          </div>
        )}

        {AuthServices.is("SYSTEM_ADMIN") && (
          <MenuItem
            onClick={() => {
              router.push("/profile");
              handleClose();
            }}
          >
            Profile
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            router.push("/my-account");
            handleClose();
          }}
        >
          My account
        </MenuItem>
        <MenuItem
          onClick={async () => {
            AuthServices.onLogout();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
