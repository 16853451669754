import { Avatar, IconButton, Tooltip, Typography } from "@mui/material";
import { CommonDateUtils } from "commonlib";
import { useAuth } from "contexts/AuthContext";
import { addSeconds } from "date-fns";
import React from "react";
import AuthServices from "services/AuthServices";
import { ISystemUser } from "types/system-users";

export function LoggedInUserAvatar(props: {
  withName?: boolean;
  handleClick: (e: any) => void;
}) {
  const { user } = useAuth();

  let [serverDateTime, setServerDateTime] = React.useState<any>(
    (user as any)?.ServerDateTime
  );
  React.useEffect(() => {
    let timer = setInterval(() => {
      setServerDateTime((date: any) => {
        if (!date) return null;
        return CommonDateUtils.format(
          addSeconds(new Date(date), 1),
          "yyyy-MM-dd hh:mm:ss a"
        );
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const localDateTime = CommonDateUtils.format(
    new Date(),
    "yyyy-MM-dd hh:mm:ss a"
  );

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.withName && (
        <div>
          <Typography>
            {user?.FirstName} {user?.LastName}
          </Typography>
          {/* Temp code to see server time */}
          {AuthServices.is("SYSTEM_ADMIN") && (
            <Tooltip title="Server">
              <div style={{ fontSize: 10 }}>{serverDateTime}</div>
            </Tooltip>
          )}
          <Tooltip title="Local">
            <div style={{ fontSize: 10 }}>{localDateTime}</div>
          </Tooltip>
        </div>
      )}
      &nbsp;
      <IconButton
        size="large"
        edge="end"
        onClick={props.handleClick}
        color="inherit"
      >
        <Avatar>{user ? getUserInitial(user) : undefined}</Avatar>
      </IconButton>
    </div>
  );
}

function getUserInitial(user: ISystemUser) {
  return `${user.FirstName?.substring(0, 1) || ""}${
    user.LastName?.substring(0, 1) || ""
  }`;
}
