import { useList } from "hooks/commonQuries";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { IMenuItem } from "types/common";

function flattenNav(array: IMenuItem[]) {
  var result: IMenuItem[] = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.Children)) {
      result = result.concat(flattenNav(a.Children));
    }
  });
  return result;
}

export default function AppTitle() {
  const navList = useList<IMenuItem>("nav-list");
  const router = useRouter();
  let title = "Advance Automation";
  const flatNav = useMemo(() => flattenNav(navList.data || []), [navList.data]);
  if (navList.data && flatNav) {
    let obj = flatNav.find((item) => item?.Path?.startsWith(router.pathname));
    if (obj?.Title) title = `${obj?.Title} - ${title}`;
  }
  return (
    <Head>
      <title>{title}</title>
    </Head>
  );
}
