import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import React, {
  ComponentProps,
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import useIsMobile from "./userIsMobile";

interface IUseModalProps {
  title?: string;
  noTitle?: boolean;
  maxWidth?: any;
  dialogProps?: Partial<ComponentProps<typeof Dialog>>;
  stopRenderingWithoutContext?: boolean;
  onComplete?: (o?: any) => void;
}

export interface IModalComponentProps {
  onComplete: (o?: any) => void;
  toggle: (open?: boolean) => void;
  key?: string;
  contextObject?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      padding: "8px 8px",
    },
    closeButton: {
      padding: "4px",
    },
  })
);

interface ICntxt {
  open: boolean;
  toggle: (o?: any) => void;
  onComplete: (o?: any) => void;
  data?: any;
}
const Cntxt = createContext<ICntxt>({
  open: false,
  toggle: () => undefined,
  onComplete: () => undefined,
});
export const useModalContext = () => useContext(Cntxt);

/**
 * @function useModal 
 * @deprecated <div style="background-color: #FF0000; color: #FFFF00; padding: 5px; border-radius: 5px; font-weight: bold;"> Deprecated. 
 replaced by `showDialog` and `showConfirmDialog`</div>
 * @category Frontend
 * @subcategory Use Modal
 * @param {ReactNode | Function} children - Modal Body. This will be body component. If you want to pass It can be string, jsx or callback that receive IModalComponentProps. 
 * - if you want use IModalComponentProps inside Body component of modal you use pass callback here. 
 ```
// Pass all IModalComponentProps to Modal Body Component
const modal = useModal(
  (props) => <AddUpdateBookmark {...props} bookmarkIndex={bookmarkIndex} />,
  {
    noTitle: true, // This will not render title and title needs to be rendered inside body using <ModalTitle />
    onComplete: () => { 
      modal.toggle(false);
      list.refetch();
    },
    maxWidth: "md",
  }
);
  ```
 * @param {Object} props
 * @param {string} props.title - Title that need to be shown on modal. 
 * - Well, This method is useful for simple case. But, This title do not have access to the `state` of body component. That's why we use `noTitle` many times and 
 * render <ModalTitle /> inside the modal body
 * @param {boolean} props.noTitle - Do not show title. 
 * @param {xs|sm|md|lg|xl} props.maxWidth - Dialog width similar to mui maxWidth
 * @param {object} props.dialogProps - Extra dialog props needs to be supplied to <Dialog />  component
 * @param {boolean} props.stopRenderingWithoutContext - Do not render body component if there is no context provided. 
 * @param {Function} props.onComplete - Callback function that will be called from inside Modal Body. This suggest that work of modal is complete. 
 *  Parent can close modal or call show success on this callback. 
 * @param TContextObject This is template param passed for typescript. You can pass which typeof context object you want to use. 
 * @example
// Init Hook
const modal = useModal(
  (props) => <BodyComponent {...props}/>,
  {
    title: "Some title",
    onComplete: () => {
      modal.toggle(false); // Close on complete
      list.refetch(); // Refresh data table
    },
    maxWidth: "md",
  }
);

// Make sure to do this inside jsx of current component otherwise Modal will not show. 
{modal.render()}

// Open modal by calling
modal.toggle(true);

// you can pass context object to toggle
modal.toggle(true, {id: "some id"});

// Close modal by calling 
modal.toggle(false);
 */
export default function useModal<TContextObject = any>(
  children: ReactNode | ((props: IModalComponentProps) => ReactNode),
  props: IUseModalProps = {}
) {
  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState<TContextObject>();
  const classes = useStyles({});
  const isMobile = useIsMobile();

  const toggle = (o?: any, data?: any) => {
    setOpen(typeof o === "boolean" ? o : !open);
    setCurrentData(data);
  };

  const onComplete = (o?: any) => {
    if (props.onComplete) props.onComplete(o);
  };

  function renderChildren() {
    if (typeof children === "function") {
      if (props.stopRenderingWithoutContext && !currentData) return null;
      return children({
        onComplete,
        toggle,
        contextObject: currentData,
        key: currentData ? JSON.stringify(currentData) : undefined,
      });
    }
    return children;
  }

  function render() {
    return (
      <Cntxt.Provider value={{ open, toggle, onComplete, data: currentData }}>
        <Dialog
          open={open}
          onClose={() => toggle(false)}
          maxWidth={props.maxWidth || "sm"}
          fullWidth
          fullScreen={isMobile}
          {...props.dialogProps}
        >
          {open && (
            <>
              {!props.noTitle && (
                <DialogTitle>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography color="inherit" width="100%">
                      {props.title}
                    </Typography>
                    <Typography style={{ flexGrow: 1 }}></Typography>

                    <IconButton
                      style={{ backgroundColor: "white" }}
                      onClick={toggle}
                      className={classes.closeButton}
                    >
                      <CloseIcon color={"primary"} />
                    </IconButton>
                  </div>
                </DialogTitle>
              )}
              <DialogContent style={{ paddingTop: 8 }}>
                {renderChildren()}
              </DialogContent>
            </>
          )}
        </Dialog>
      </Cntxt.Provider>
    );
  }
  return {
    toggle,
    open,
    render,
  };
}
