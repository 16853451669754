import { where } from "firebase/firestore";
import { Store } from "pullstate";
import { getWindow } from "utils/winUtils";

export const drawerWidths = {
  mini: 60,
  full: 260,
};

interface ICommonStore {
  drawerWidth: number;
  selectedBottomTab: string;
  menuCollapseStatus: {
    [key: string]: boolean;
  };
  isDrawerMini: boolean;
  isFixedMenu: boolean;
  isDrawerOpenOnMobile: boolean;
  forceRender: boolean;
}

export const CommonStore = new Store<ICommonStore>({
  drawerWidth: drawerWidths.mini,
  selectedBottomTab: "",
  menuCollapseStatus: {},
  isDrawerMini: true,
  isFixedMenu: false,
  isDrawerOpenOnMobile: false,
  forceRender: false,
});

export function setMenuCollapse(key: string, isExpanded: boolean) {
  CommonStore.update((s) => {
    s.menuCollapseStatus = { ...s.menuCollapseStatus, [key]: isExpanded };
  });
}

export function setDrawerWidth(size?: "mini" | "full") {
  CommonStore.update((s) => {
    if (s.isFixedMenu) return; // No change in drawer width in fixed
    if (size !== undefined) {
      s.drawerWidth = drawerWidths[size];
    } else {
      s.drawerWidth =
        s.drawerWidth === drawerWidths.mini
          ? drawerWidths.full
          : drawerWidths.mini;
    }
    s.isDrawerMini = s.drawerWidth === drawerWidths.mini;
  });
}

export function setMenuFixed(isFixedMenu?: boolean) {
  CommonStore.update((s) => {
    let newVal = isFixedMenu !== undefined ? isFixedMenu : !s.isFixedMenu;
    s.isFixedMenu = newVal;
    if (newVal) {
      s.isDrawerMini = false;
      s.drawerWidth = drawerWidths.full;
    } else {
      // s.isDrawerMini = true;
      // s.drawerWidth = drawerWidths.mini;
    }
  });
}

export function getContentHeight() {
  let win = getWindow();
  if (!win) return "100vh";
  let wHeight = win.innerHeight;
  let divToolbar = document.getElementById("divToolbar");
  if (divToolbar) {
    wHeight -= divToolbar.clientHeight;
  }
  return wHeight;
}

export function setIsDrawerOpenOnMobile(open?: boolean) {
  CommonStore.update((s) => {
    let newVal = open !== undefined ? open : !s.isDrawerOpenOnMobile;
    s.isDrawerOpenOnMobile = newVal;
  });
  setDrawerWidth("full");
}


export function setForceRender(forceRender: boolean) {
  CommonStore.update((s) => {
    s.forceRender = forceRender;
  });
  setDrawerWidth("full");
}
