import {
  Autocomplete,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import RIcon from "common/misc/RIcon";
import Fuse from "fuse.js";
import { useList } from "hooks/commonQuries";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Utils from "services/Utils";
import { IMenuItem } from "types/common";
import { getWindow, setWindow } from "utils/winUtils";

interface IGlobalSearchModal {
  onComplete: (o?: any) => void;
}

export default function GlobalSearchModal(props: IGlobalSearchModal) {
  const navControls: IMenuItem[] | undefined =
    useList<IMenuItem>("nav-list").data;
  const router = useRouter();
  const theme = useTheme<Theme>();

  useEffect(() => {
    const onKeyDownUp = (e: KeyboardEvent) => {
      setWindow("ctrlPressed", e.ctrlKey);
    };

    document.addEventListener("keydown", onKeyDownUp);
    document.addEventListener("keyup", onKeyDownUp);
    return () => {
      document.removeEventListener("keydown", onKeyDownUp);
      document.removeEventListener("keyup", onKeyDownUp);
    };
  }, []);

  let menuAry: any = [];

  function menuList(navLst?: IMenuItem[], parentPath: string = "") {
    if (!navLst) return [];
    for (const item of navLst) {
      if (item.Path) {
        let displayPath = parentPath
          ? `${parentPath} -> ${item.Title}`
          : item.Title;
        menuAry.push({ ...item, displayPath });
      } else if (item.Children && item.Children.length > 0) {
        let displayPath = parentPath
          ? `${parentPath} -> ${item.Title}`
          : item.Title;
        menuList(item.Children, displayPath);
      }
    }
  }

  menuList(navControls);
  return (
    <div>
      <Typography
        align="center"
        color={theme.palette.error.main}
        variant="caption"
      >
        Try <b>Ctrl+Windows</b> or <b>Ctrl+K</b> next time you wanna search{" "}
      </Typography>
      <Autocomplete
        // sx={{ p: 0, m: 0, borderColor: "green" }}
        fullWidth
        renderInput={(params) => (
          <TextField
            color="success"
            {...params}
            inputRef={(input) => {
              if (input != null) {
                input.focus();
              }
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
              sx: { height: "37px" },
            }}
            placeholder="Search Menu"
            autoComplete="off"
            key={params.id}
          />
        )}
        renderOption={(
          renderProps,
          option: IMenuItem & { displayPath: string }
        ) => {
          return (
            <ListItem
              {...renderProps}
              divider
              disableGutters
              // @ts-ignore
              component="a"
              href={option.Path || ""}
            >
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: Utils.stringToColor(option.Title),
                    height: "45px",
                    width: "45px",
                  }}
                >
                  {option.Icon ? (
                    <RIcon name={option.Icon as any} />
                  ) : (
                    Utils.getFirstCharCap(option.Title)
                  )}
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={option.Title}
                secondary={option.displayPath}
              />
            </ListItem>
          );
        }}
        options={menuAry || []}
        getOptionLabel={(option) => option.Title}
        isOptionEqualToValue={(option, value) => true}
        onChange={(e, option) => {
          if (option?.Path) {
            if (getWindow()?.ctrlPressed) window.open(option.Path);
            else router.push(option.Path);
            props.onComplete();
          }
        }}
        filterOptions={(optionList, state) => {
          const fuse = new Fuse(optionList, {
            keys: ["Title", "Path"],
          });
          return fuse.search(state.inputValue, { limit: 5 }).map((i) => i.item);

          // let keyword = state.inputValue || "";
          // return optionList.filter((option) => {
          //   let aryVariations: any[] = [];
          //   if (option.Title) {
          //     let name = option.Title?.toLowerCase();
          //     aryVariations.push(name);
          //     aryVariations.push(name.replace(/ /g, "").toLowerCase());
          //     aryVariations = aryVariations.concat(name.split(" "));
          //   }
          //   if (option.Path) aryVariations.push(option.Path);
          //   return aryVariations.some(
          //     (variation) =>
          //       RegExp(keyword, "i").test(variation) ||
          //       RegExp(keyword.replace(/ /g, "").toLowerCase(), "i").test(
          //         variation
          //       )
          //   );
          // });
        }}
        autoHighlight
        clearOnBlur={false}
      />
    </div>
  );
}
