import Box from "@mui/material/Box";
import AppTitle from "common/app/AppTitle";
import NoSSR from "common/NoSSR";
import useIsMobile from "hooks/userIsMobile";
import { parseErrorString } from "hyper-utils";
import { CreateUserAccessHistory } from "layouts/common/NavigationListener";
import { useEffect } from "react";
import { CommonStore, drawerWidths } from "stores/commonStore";
import MainAppBar from "./MainAppBar";
import MainDrawer from "./MainDrawer";
import TaskPanel from "./TaskPanel";

export default function MainLayout(props: {
  children: any;
  noScroll?: boolean; // It will disable overflow scroll on top level. children can decide their own overflow
  NoMasterClientSelector?: boolean;
}) {
  const { drawerWidth, isFixedMenu } = CommonStore.useState((s) => s);
  const isMobile = useIsMobile();
  let menuPadding = drawerWidth;
  if (!isFixedMenu) {
    menuPadding = drawerWidths.mini;
  }
  if (isMobile) menuPadding = 0;

  useEffect(() => {
    CreateUserAccessHistory().catch((ex) => {
      console.log(
        `Error occurred while adding navigation history. ${parseErrorString(
          ex
        )}`
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <NoSSR>
        <MainDrawer />
      </NoSSR>
      <NoSSR>
        <AppTitle />
      </NoSSR>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          transition: "all 0.3s",
          height: "100vh",
          overflow: "hidden",
        }}
        style={{ paddingLeft: menuPadding }}
      >
        <NoSSR>
          <MainAppBar NoMasterClientSelector={props.NoMasterClientSelector} />
        </NoSSR>
        <Box
          id="divContentArea"
          sx={{
            ...(props.noScroll ? {} : { overflow: "auto" }),
            height: "100%",
          }}
        >
          {props.children}
        </Box>
      </Box>
      <TaskPanel />
      {/* <LoadScripts /> */}
    </Box>
  );
}
