import {
  Autocomplete,
  Avatar,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import useIsMobile from "hooks/userIsMobile";
import React from "react";
import { AuthStore, setSelectedMasterClient } from "stores/authStore";

export default function MasterClientSelector() {
  const { selectedMasterClient, user } = AuthStore.useState();
  const isMobile = useIsMobile();

  if (!user?.Access?.MasterClients) return null;

  let MasterClients = [...user.Access.MasterClients].sort((a, b) =>
    a.CorporationName.localeCompare(b.CorporationName)
  );

  return (
    <Autocomplete
      options={MasterClients}
      value={(selectedMasterClient as any) || null}
      style={{ minWidth: isMobile ? 200 : 250 }}
      onChange={(e, obj) => {
        setSelectedMasterClient(obj);
      }}
      isOptionEqualToValue={(o, v) => {
        return o._id === v._id;
      }}
      disabled={user?.Access?.MasterClients.length === 1}
      getOptionLabel={(o) => o.CorporationName}
      ListboxProps={{
        style: {
          maxHeight: 650,
        },
      }}
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemAvatar>
              <Avatar src={option.LogoURL}>M</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={option.CorporationName}
              // secondary={option.DBA}
            />
          </ListItem>
        );
      }}
      renderInput={(props) => (
        <TextField
          {...props}
          inputProps={{
            ...props.inputProps,
            autoComplete: "off",
            style: { color: "blue", fontWeight: "bold" },
          }}
          // InputProps={{
          //   ...props.InputProps,
          //   startAdornment: (
          //     <InputAdornment position="start">
          //       <Avatar
          //         style={{ height: 35, width: 35 }}
          //         src={selectedMasterClient?.LogoURL}
          //       >
          //         M
          //       </Avatar>
          //     </InputAdornment>
          //   ),
          // }}
        />
      )}
    ></Autocomplete>
  );
}
