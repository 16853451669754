import { enqueueSnackbar, OptionsObject } from "notistack";
import Utils from "services/Utils";
import { setWindow } from "./winUtils";
type position =
  | "top-left"
  | "top-center"
  | "top-right"
  | "bottom-right"
  | "bottom-center"
  | "bottom-left";

interface ISnackBarOptions {
  position?: position;
  timeout?: number;
}
class SnackBarUtils {
  static Show(message: any, options?: OptionsObject) {
    let id = enqueueSnackbar({
      message: <div>{message}</div>,
      ...options,
    });
  }
}
setWindow("SnackBarUtils", SnackBarUtils);

export default SnackBarUtils;
