import { ICMSQuery, ICMSQueryOptions } from "backendsdk";
import { IDataList } from "common/DataList/DataList";
import { IDataTable } from "common/DataTable/DataTableTypes";
import { AlertUtils } from "hyper-utils";
import { useRef, useState } from "react";
import { useMutation, useQuery, UseQueryOptions } from "react-query";
import AuthServices from "services/AuthServices";
import { bSdk } from "services/BackendSDKService";

export function useList<T>(collectionKey: string, options?: ICMSQueryOptions) {
  return useQuery<T[]>([collectionKey], async () => {
    const result = await bSdk.cmsQuery(collectionKey as any, undefined, {
      authToken: AuthServices.getToken(),
      ...options,
    });
    return result?.data;
  });
}

export function useDataTableList<T>(
  collectionKey: string,
  options: ICMSQueryOptions & {
    key?: string[];
    isInfinitePagination?: boolean;
    noCache?: boolean;
    fetchCB?: (query: ICMSQuery) => any;
  } = {}
) {
  const { key, ...otherOptions } = options;
  const count = useRef<number>();
  const tableRef = useRef<any>();
  const [state, _setState] = useState({
    page: otherOptions.query?.page ?? 1,
    pageSize: otherOptions.query?.pageSize ?? 10,
    sort: otherOptions.query?.sort || "CreatedAt",
    sortOrder: otherOptions.query?.sortOrder || "desc",
  });
  function setState(obj: Partial<typeof state>) {
    _setState({ ...state, ...obj });
  }

  const query = useQuery<T[]>(
    [collectionKey, ...(key || []), state],
    async (ec: any) => {
      let result: any;
      if (typeof options.fetchCB === "function") {
        result = await options.fetchCB({ ...state, includeCount: true });
      } else {
        result = await bSdk.cmsQuery(collectionKey as any, undefined, {
          authToken: AuthServices.getToken(),
          ...otherOptions,
          query: {
            page: state.page,
            pageSize: state.pageSize,
            includeCount: true,
            sort: state.sort,
            sortOrder: state.sortOrder,
          },
        });
      }
      count.current = result?.TotalRowCount;
      if (options.isInfinitePagination && query.data) {
        result.data = query.data?.concat(result.data);
      }
      return result?.data;
    },
    {
      keepPreviousData: options.isInfinitePagination,
      cacheTime: options.noCache ? 0 : undefined,
      retry: options.noCache ? false : undefined,
    }
  );
  function setPage(page: number) {
    setState({ page });
  }

  function getDataTableProps<T2 extends object>(): Partial<IDataTable<T2>> {
    return {
      data: query.data || [],
      page: state.page - 1,
      onChangePage: (page: number, pageSize: number) => {
        setState({ pageSize, page: page + 1 });
      },
      isLoading: query.isFetching,
      error: query.error,
      totalCount: count.current,
      options: { pageSize: state.pageSize },
      onReload: () => {
        query.refetch();
      },
      tableRef,
    };
  }

  function getDataListProps(): Partial<IDataList<T>> {
    let hasNextPage = !!(
      state.pageSize &&
      count.current &&
      state.page < (count.current || 0) / state.pageSize
    );

    return {
      data: query.data || [],
      page: state.page - 1,
      onPageChange: (page: number, pageSize: number) => {
        setState({ pageSize, page: page + 1 });
      },
      isLoading: query.isFetching,
      error: query.error,
      totalCount: count.current,
      pageSize: state.pageSize,
      hasNextPage,
      // onReload: () => {
      //   query.refetch();
      // },
      // tableRef,
    };
  }

  function reloadData() {
    query.remove();
    query.refetch();
  }

  return {
    ...query,
    ...state,
    setPage,
    setState,
    totalCount: count.current || 0,
    get dataTableProps() {
      return getDataTableProps();
    },
    get dataListProps() {
      return getDataListProps();
    },
    reloadData,
  };
}

export function useDetail<T>(
  collectionKey: string,
  id: string,
  queryOptions?: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  >
) {
  return useQuery<T>(
    [collectionKey, id],
    async () => {
      if (!id) throw new Error(`Provide id to get for ${collectionKey}.`);
      const result = await bSdk.cmsQuery(collectionKey as any, undefined, {
        route: `/${id}`,
        authToken: AuthServices.getToken(),
      });
      return result?.data;
    },
    queryOptions
  );
}

export function useAddOrUpdate(
  collectionKey: string,
  options?: { before?: (data: any) => void; after?: (data?: any) => void }
) {
  return useMutation([collectionKey], async (data: any) => {
    if (options?.before) await options?.before(data);
    let isUpdate = !!data._id;
    const result = await bSdk.cmsQuery(collectionKey as any, data, {
      route: isUpdate ? `/${data._id}` : "/",
      method: isUpdate ? "PUT" : "POST",
      authToken: AuthServices.getToken(),
    });
    if (options?.after) await options?.after(result?.data);
    return result?.data;
  });
}

export function useDelete(
  collectionKey: string,
  options: { confirmMessage?: string } = {}
) {
  return useMutation([collectionKey], async (id: any) => {
    if (!id) throw new Error("Provide id to delete.");
    let confirm = await AlertUtils.showConfirm(
      options.confirmMessage || `Do you want to delete this?`
    );
    if (!confirm) return null;
    const result = await bSdk.cmsQuery(collectionKey as any, undefined, {
      route: `/${id}`,
      method: "DELETE",
      authToken: AuthServices.getToken(),
    });
    return result?.data;
  });
}

export function useDeleteMany(collectionKey: string) {
  return useMutation([collectionKey], async (ids: any[]) => {
    if (!Array.isArray(ids)) throw new Error("Provide ids to delete.");
    let confirm = await AlertUtils.showConfirm(`Do you want to delete this?`);
    if (!confirm) return null;
    const result = await bSdk.cmsQuery(
      collectionKey as any,
      { _ids: ids },
      {
        route: `/batch`,
        method: "DELETE",
        authToken: AuthServices.getToken(),
      }
    );
    return result?.data;
  });
}
